<template>
  <div class="animated fadeIn">
    <b-card>
      <DetailsFormWrapper
        ref="form"
        :id="id"
        :model="model"
        :data="data"
        header="Shift template submission"
        @loaded="onFormLoad"
      >
        <slot name="content">
          <b-row>
            <b-col lg="3" md="6" sm="6">
              <FormDateTime
                v-model="data['Start Date']"
                :properties="entity('Start Date')"
                @change="onFieldChange"
              ></FormDateTime>
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <FormSelect
                v-model="data['Week Day']"
                :properties="entity('Week Day')"
                @change="onFieldChange"
              ></FormSelect>
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <FormTime
                v-model="data['Start Time']"
                :properties="entity('Start Time')"
                @change="onFieldChange"
              ></FormTime
            ></b-col>
            <b-col lg="3" md="6" sm="6">
              <FormTime
                v-model="data['End Time']"
                :properties="entity('End Time')"
                @change="onFieldChange"
              ></FormTime
            ></b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="6" sm="6">
              <FormSelect
                v-model="data['Group']"
                :properties="entity('Group')"
                @change="onFieldChange"
              ></FormSelect>
            </b-col>

            <b-col lg="6" md="6" sm="6">
              <FormSelect
                v-model="data['Responsibles']"
                :properties="entity('Responsibles')"
                @change="onFieldChange"
              ></FormSelect>
            </b-col>
          </b-row>
        </slot>
      </DetailsFormWrapper>
    </b-card>
  </div>
</template>

<script>
import models from "@/models";
import modelHelpers from "@/models/helpers";

export default {
  name: "ShiftTemplateSubmission",
  components: {},
  props: {
    id: {
      type: [String, Number],
      default: ""
    }
  },
  data: function() {
    return {
      isReadOnlyRecord: false,
      model: models.trackTime.shiftTemplate,
      data: {},
      controls: {},
      entity: modelHelpers.entity
    };
  },

  created() {
    this.setDefaults();
  },
  async mounted() {},
  methods: {
    setDefaults() {
      this.data = modelHelpers.getEmptyEntitiesObject(this.model.entities);
    },
    onFormLoad(payload) {
      if (!payload) return;

      this.data = { ...this.data, ...payload };

      this.data["Week Day"] = {
        id: payload["Week Day"],
        label: payload["Week Day"]
      };
    },
    async onFieldChange(e) {
      console.log("onFieldChange", e);

      if (this.$form.mode(this) === this.$constants.FORM_MODE.VIEW)
        this.$refs["form"]
          .save("view")
          .then(() => this.$refs["form"].initialize());
    },
    onItemsChange() {}
  },
  watch: {}
};
</script>

<style></style>
