var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('DetailsFormWrapper', {
    ref: "form",
    attrs: {
      "id": _vm.id,
      "model": _vm.model,
      "data": _vm.data,
      "header": "Shift template submission"
    },
    on: {
      "loaded": _vm.onFormLoad
    }
  }, [_vm._t("content", function () {
    return [_c('b-row', [_c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormDateTime', {
      attrs: {
        "properties": _vm.entity('Start Date')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Start Date'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Start Date', $$v);
        },
        expression: "data['Start Date']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      attrs: {
        "properties": _vm.entity('Week Day')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Week Day'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Week Day', $$v);
        },
        expression: "data['Week Day']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormTime', {
      attrs: {
        "properties": _vm.entity('Start Time')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Start Time'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Start Time', $$v);
        },
        expression: "data['Start Time']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormTime', {
      attrs: {
        "properties": _vm.entity('End Time')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['End Time'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'End Time', $$v);
        },
        expression: "data['End Time']"
      }
    })], 1)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      attrs: {
        "properties": _vm.entity('Group')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Group'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Group', $$v);
        },
        expression: "data['Group']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      attrs: {
        "properties": _vm.entity('Responsibles')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Responsibles'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Responsibles', $$v);
        },
        expression: "data['Responsibles']"
      }
    })], 1)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }